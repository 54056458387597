import { useEffect, useState, useRef } from "react";
import { ReactComponent as ArrowDown } from "../static/icons/arrow-down.svg";
import { ReactComponent as BlueLine } from "../static/icons/blue-line.svg";
import { ReactComponent as BlueLineMobile } from "../static/icons/blue-line-mobile.svg";
import { ReactComponent as MainLogo } from "../static/icons/main-logo.svg";
import { ReactComponent as LongLine } from "../static/icons/long-line.svg";
import { ReactComponent as MobileTabletLine } from "../static/icons/motaline.svg";
import MainBg1 from "../static/images/mainbg1.jpg";
import MainBg2 from "../static/images/mainbg2.jpg";
import MainBg3 from "../static/images/mainbg3.jpg";
import { forwardRef } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase";

const Intro1 = require("../static/images/intro1.png");
const Intro2 = require("../static/images/intro2.png");
const Intro3 = require("../static/images/intro3.png");
const Intro4 = require("../static/images/intro4.png");
const Intro5 = require("../static/images/intro5.png");
const Intro6 = require("../static/images/intro6.png");
const AltnmTablet = require("../static/images/altnm-tablet.png");
const AltnmMobile = require("../static/images/altnm-mobile.png");

type RefType = HTMLDivElement;
type PropsType = any;

const Introduce = forwardRef<RefType, PropsType>((props, ref) => {
  const [introduce, setIntroduce] = useState<any>();
  const { onMoveToElement } = props;
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);

  useEffect(() => {
    const unsubscribeIntroduce = onSnapshot(
      doc(firestore, "introduce", "items"),
      (doc) => {
        if (doc.data()) {
          try {
            setIntroduce(doc.data());
          } catch (e) {
            console.log(e);
          }
        }
      }
    );

    return () => {
      unsubscribeIntroduce();
    };
  }, []);

  //Carousel 자동 슬라이드를 위한 타이머
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentCarouselIndex((prev) => (prev === 2 ? 0 : prev + 1));
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.style.marginLeft = `-${currentCarouselIndex * 100}%`;
    }
  }, [currentCarouselIndex]);

  // useEffect(() => {
  //   if (introduce === undefined) return;
  //   console.log(introduce)
  // }, [introduce]);

  return (
    <div className=" flex w-screen flex-col justify-center  lg:pb-[28rem]">
      <div className="relative flex h-screen  w-full items-center">
        <div className="flex h-screen w-full">
          <div className="maingBgGradient absolute z-10 h-full w-full bg-pink-100 " />
          <div className="relative flex h-full w-full">
            <div className="h-full overflow-hidden">
              <div
                className="introduceCarouselAnimation flex h-full w-full"
                ref={carouselRef}
              >
                <div className="h-full w-full flex-none shrink-0">
                  <img
                    alt=""
                    src={MainBg1}
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="h-full w-full flex-none shrink-0">
                  <img
                    alt=""
                    src={MainBg2}
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="h-full w-full flex-none shrink-0">
                  <img
                    alt=""
                    src={MainBg3}
                    className="h-full w-full object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute top-[22rem] left-[50%] z-20 flex translate-x-[-50%]  flex-col gap-[0.9rem] md:top-[50%] md:left-[15.78vw] md:translate-x-0  ">
          <h2 className="font-['Pretendard'] text-[2.275rem] font-bold leading-[2.715rem] text-[#ffffff] md:text-[3.7rem] md:leading-[44.16px] lg:text-[4.8rem] lg:leading-[5.728rem]">
            스마트 관광의 모든것
          </h2>
          <h1 className="font-['Pretendard'] text-[3.791rem] font-bold leading-[5.164rem] text-[#ffffff] md:text-[6.17rem] md:leading-[84px] lg:text-[8rem] lg:leading-[10.896rem]">
            ALT&M
          </h1>
        </div>
        <div
          className="absolute bottom-[6.4rem] left-2/4 z-20 animate-bounce cursor-pointer"
          onClick={onMoveToElement}
        >
          <ArrowDown />
        </div>
      </div>

      <div
        className="flex-center box-border flex-col gap-[4.5rem] bg-[#ffffff] py-[15rem] lg:h-[110rem] lg:gap-[11rem] lg:py-0"
        ref={ref}
      >
        <div className="flex-center flex-col justify-center gap-[1.6rem] md:gap-[32.4px] lg:gap-[4rem]">
          <div className="hidden lg:block">
            <BlueLine />
          </div>
          <div className="block lg:hidden">
            <BlueLineMobile />
          </div>
          <p className="font-['Pretendard'] text-[1.5rem] font-semibold leading-[1.79rem] text-[#434343] md:text-[2.3rem] md:leading-[27.5px] lg:text-[3.2rem] lg:leading-[3.819rem]">
            회사소개
          </p>
        </div>

        <div className="lg:flex-center hidden">
          <MainLogo />
        </div>
        <div className="flex-center flex  items-center justify-center  md:hidden">
          <img alt="" src={AltnmMobile} />
        </div>
        <div className="flex-center hidden items-center justify-center md:flex lg:hidden">
          <img alt="" src={AltnmTablet} />
        </div>

        <div className="flex-center px-[16vw] md:px-[25.38vw] lg:px-[30vw]">
          <p className="hidden whitespace-pre-line break-keep text-center font-['Pretendard'] text-[2rem] font-light leading-[3.1rem] text-[#474747] md:block md:text-[1.7rem] md:leading-[25px]">
            알트앤엠 주식회사는 스마트 관광 플랫폼 제공, 관광 상품 및 콘텐츠의
            개발/운영 등을 위해{"\n"} 2019년 8월에 경상북도 도청 소재지 안동에
            설립한 스마트 관광 전문 지역 기업입니다.
          </p>
          <p className="block whitespace-pre-line break-keep text-center font-['Pretendard'] text-[1.4rem] font-light leading-[2rem] text-[#6a6a6a] md:hidden ">
            알트앤엠 주식회사는 스마트 관광 플랫폼 제공 관광 상품 및 콘텐츠의
            개발/운영 등을 위해 2019년 8월에 경상북도 도청 소재지 안동에 설립한
            스마트 관광 전문 지역 기업입니다.
          </p>
        </div>
      </div>

      {/** Pc Grid */}
      <div className="hidden grid-cols-3 grid-rows-1 md:grid ">
        <div className="relative">
          <img
            alt=""
            src={Intro1}
            className="w-full object-cover  md:h-[310.26px] lg:h-[49.9rem]"
          />
          <p className="absolute z-10 font-['Pretendard'] text-[2rem] font-medium leading-[2.333rem] text-[#f8f8f8] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] md:top-[80%]  md:left-[5%] lg:top-[43.504rem] lg:left-[4.3rem] ">
            관광 상품 개발, 판매, 운영
          </p>
          <div className="absolute top-0 h-full w-full bg-gradient-to-t from-[rgba(0,0,0,0.4)] via-[rgba(0,0,0,0.05)] to-transparent" />
        </div>
        <div className="relative">
          <img
            alt=""
            src={Intro2}
            className="w-full object-cover md:h-[310.26px] lg:h-[49.9rem]"
          />
          <p className="absolute z-10 font-['Pretendard'] text-[2rem] font-medium leading-[2.333rem] text-[#f8f8f8] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] md:top-[80%] md:left-[5%] lg:top-[43.504rem] lg:left-[4.3rem] ">
            지역 특화 사업, 특산물 유통 활성화
          </p>
          <div className="absolute top-0 h-full w-full bg-gradient-to-t from-[rgba(0,0,0,0.4)] via-[rgba(0,0,0,0.05)] to-transparent" />
        </div>
        <div className="relative">
          <img
            alt=""
            src={Intro3}
            className="w-full object-cover md:h-[310.26px] lg:h-[49.9rem]"
          />{" "}
          <p className="absolute z-10 font-['Pretendard'] text-[2rem] font-medium leading-[2.333rem] text-[#f8f8f8] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] md:top-[80%] md:left-[5%] lg:top-[43.504rem] lg:left-[4.3rem] ">
            관광 콘텐츠 기획, 개발
          </p>
          <div className="absolute top-0 h-full w-full bg-gradient-to-t from-[rgba(0,0,0,0.4)] via-[rgba(0,0,0,0.05)] to-transparent" />
        </div>
      </div>
      <div className="hidden grid-cols-[30%_1fr_33%] grid-rows-1 md:grid">
        <div className="relative">
          <img
            alt=""
            src={Intro4}
            className="w-full object-cover md:h-[310.26px] lg:h-[49.9rem]"
          />{" "}
          <p className="absolute z-10 font-['Pretendard'] text-[2rem] font-medium leading-[2.333rem] text-[#f8f8f8] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] md:top-[80%] md:left-[5%] lg:top-[43.504rem] lg:left-[4.3rem] ">
            모바일 바우처/티켓 개발, 판매, 운영
          </p>
          <div className="absolute top-0 h-full w-full bg-gradient-to-t from-[rgba(0,0,0,0.4)] via-[rgba(0,0,0,0.05)] to-transparent" />
        </div>
        <div className="relative">
          <img
            alt=""
            src={Intro5}
            className="w-full object-cover md:h-[310.26px] lg:h-[49.9rem]"
          />{" "}
          <p className="absolute z-10 font-['Pretendard'] text-[2rem] font-medium leading-[2.333rem] text-[#f8f8f8] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] md:top-[80%] md:left-[5%] lg:top-[43.504rem] lg:left-[4.3rem] ">
            스마트 관광 플랫폼 개발, 운영
          </p>
          <div className="absolute top-0 h-full w-full bg-gradient-to-t from-[rgba(0,0,0,0.4)] via-[rgba(0,0,0,0.05)] to-transparent" />
        </div>
        <div className="relative">
          <img
            alt=""
            src={Intro6}
            className="w-full object-cover md:h-[310.26px] lg:h-[49.9rem]"
          />{" "}
          <p className="absolute z-10 font-['Pretendard'] text-[2rem] font-medium leading-[2.333rem] text-[#f8f8f8] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] md:top-[80%] md:left-[5%] lg:top-[43.504rem] lg:left-[4.3rem] ">
            AI 챗봇 카카오채널 개발, 운영
          </p>
          <div className="absolute top-0 h-full w-full bg-gradient-to-t from-[rgba(0,0,0,0.4)] via-[rgba(0,0,0,0.05)] to-transparent" />
        </div>
      </div>
      {/* Mobile Grid*/}
      <div className="box-border grid w-full grid-cols-[1fr_43.43vw] grid-rows-1 overflow-x-auto md:hidden">
        <div className="relative">
          <img
            alt=""
            src={Intro1}
            className="w-full object-cover md:h-[310.26px] lg:h-[49.9rem]"
          />
          <div className="trnaslate-y-[-50%] absolute left-[2.68rem] top-[50%] flex flex-col gap-[1.5rem]">
            <h1 className="font-['Pretendard'] text-[1.5rem] font-bold leading-[0.462rem] text-[#ffffff]">
              관광 상품
            </h1>
            <p className="font-['Pretendard'] text-[1.5rem] font-normal leading-[0.462rem] text-[#ffffff]">
              개발, 판매, 운영
            </p>
          </div>
        </div>
        <div className="relative">
          <img
            alt=""
            src={Intro2}
            className="h-[17.8rem]  max-w-full object-cover"
          />
          <div className="trnaslate-y-[-50%] absolute left-[2.68rem] top-[50%] flex flex-col gap-[1.5rem]">
            <h1 className="font-['Pretendard'] text-[1.5rem] font-bold leading-[0.462rem] text-[#ffffff]">
              지역 특화 사업, 특산물
            </h1>
            <p className="font-['Pretendard'] text-[1.5rem] font-normal leading-[0.462rem] text-[#ffffff]">
              유통 활성화
            </p>
          </div>
        </div>
      </div>
      <div className="grid w-full grid-cols-[43.43vw_1fr] grid-rows-1 md:hidden">
        {" "}
        <div className="relative">
          <img
            alt=""
            src={Intro3}
            className="h-[17.8rem] w-full object-cover"
          />
          <div className="trnaslate-y-[-50%] absolute left-[2.68rem] top-[50%] flex flex-col gap-[1.5rem]">
            <h1 className="font-['Pretendard'] text-[1.5rem] font-bold leading-[0.462rem] text-[#ffffff]">
              관광 콘텐츠
            </h1>
            <p className="font-['Pretendard'] text-[1.5rem] font-normal leading-[0.462rem] text-[#ffffff]">
              기획, 개발
            </p>
          </div>
        </div>
        <div className="relative">
          <img
            alt=""
            src={Intro4}
            className="h-[17.8rem] w-full object-cover"
          />
          <div className="trnaslate-y-[-50%] absolute left-[2.68rem] top-[50%] flex flex-col gap-[1.5rem]">
            <h1 className="font-['Pretendard'] text-[1.5rem] font-bold leading-[0.462rem] text-[#ffffff]">
              모바일 바우처/티켓
            </h1>
            <p className="font-['Pretendard'] text-[1.5rem] font-normal leading-[0.462rem] text-[#ffffff]">
              개발, 판매, 운영
            </p>
          </div>
        </div>
      </div>
      <div className="grid w-full grid-cols-[1fr_43.43vw] grid-rows-1 md:hidden">
        {" "}
        <div className="relative">
          <img
            alt=""
            src={Intro5}
            className="h-[17.8rem] w-full object-cover"
          />
          <div className="trnaslate-y-[-50%] absolute left-[2.68rem] top-[50%] flex flex-col gap-[1.5rem]">
            <h1 className="font-['Pretendard'] text-[1.5rem] font-bold leading-[0.462rem] text-[#ffffff]">
              스마트 관광 플랫폼
            </h1>
            <p className="font-['Pretendard'] text-[1.5rem] font-normal leading-[0.462rem] text-[#ffffff]">
              개발, 운영
            </p>
          </div>
        </div>
        <div className="relative">
          <img
            alt=""
            src={Intro6}
            className="h-[17.8rem] w-full object-cover"
          />
          <div className="trnaslate-y-[-50%] absolute left-[2.68rem] top-[50%] flex flex-col gap-[1.5rem]">
            <h1 className="font-['Pretendard'] text-[1.5rem] font-bold leading-[0.462rem] text-[#ffffff]">
              AI 챗봇 카카오채널
            </h1>
            <p className="font-['Pretendard'] text-[1.5rem] font-normal leading-[0.462rem] text-[#ffffff]">
              개발, 운영
            </p>
          </div>
        </div>
      </div>
      {/* LONGLINE S*/}
      <div className="flex-center relative h-full min-h-screen flex-col pt-[8rem] md:pt-[10rem] lg:min-h-[140rem] lg:flex-row lg:pt-[30.9rem]">
        <div className="absolute top-[20.4rem] hidden lg:block  ">
          <LongLine />
        </div>

        <div className=" flex h-fit w-full flex-col gap-[3.5rem] lg:absolute lg:left-[50%] lg:translate-x-[-9%] lg:gap-[8.6rem] ">
          {introduce &&
            introduce.data.map((item: any, index: any) => {
              return (
                <div
                  className="flex flex-col items-center gap-[2.3rem] lg:flex-row  lg:gap-[8.7vw]"
                  key={index}
                >
                  <h3
                    className={`font-['Pretendard']  text-[2.5rem] font-medium leading-[2.321rem] text-[#474747] md:text-[2.5rem] lg:text-[3.6rem] lg:leading-[3.1rem] lg:text-[rgba(71,71,71,0.9)] `}
                  >
                    {item.year}
                  </h3>
                  <div className="flex w-full flex-col items-center gap-[1.5rem] lg:items-start lg:justify-center lg:gap-[2.5rem]">
                    {item.desc.map((desc: any, index: any) => {
                      return (
                        <p
                          key={index}
                          className="font-['Pretendard'] text-[1.2rem] font-medium leading-[1.478rem] text-[#474747] lg:text-[2rem] lg:leading-[3.1rem] lg:text-[rgba(71,71,71,0.9)]"
                        >
                          {desc}
                        </p>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="mt-[40px] block md:mt-[60px] lg:hidden">
          <MobileTabletLine />
        </div>
      </div>
      {/* LONGLINE E*/}
    </div>
  );
});

export default Introduce;
