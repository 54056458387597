import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_APIKEY_ALT,
  authDomain: process.env.REACT_APP_FB_AUTHDOMAIN_ALT,
  projectId: process.env.REACT_APP_FB_PROJECTID_ALT,
  storageBucket: process.env.REACT_APP_FB_STORAGEBUCKET_ALT,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGINGSENDERID_ALT,
  appId: process.env.REACT_APP_FB_APPID_ALT,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);
