const Footer = () => {
  return (
    <footer className="flex w-full flex-col gap-[1.33rem] bg-[#333d3e] py-[4.23rem] px-[6.4vw] md:px-[9vw] lg:mt-[37rem] lg:h-[45.9rem] lg:gap-[1.8rem] lg:py-[8.1rem] lg:px-[15.53vw]">
      <div className="flex-ic justify-between">
        <div>
          <p className="font-['Pretendard'] text-[1.1rem] font-bold leading-[1.47rem] text-[#e4e4e4] md:text-[1.8rem] md:leading-[29px] lg:text-[1.8rem] lg:leading-[2.9rem]">
            알트앤엠 주식회사
          </p>
        </div>
      </div>

      <div className="flex flex-col justify-center font-['Pretendard'] text-[1.1rem] font-medium leading-[1.75rem] text-[#e4e4e4] md:text-[1.75rem] md:leading-[29px] lg:gap-[1.1rem] lg:text-[1.747rem] lg:leading-[2.9rem]">
        <div className="flex-ic gap-[0.98rem] lg:gap-[2.4rem]">
          <div className="flex-ic gap-[1.8rem] lg:gap-[1.5rem]">
            <h3>설립일</h3>
            <p className="font-light">2019년 8월 13일</p>
          </div>
          <div className="flex-ic gap-[1.5rem]">
            <h3>사업자등록번호</h3>
            <p className="font-light">306-87-01403</p>
          </div>
        </div>
        <div className="flex flex-col  lg:flex-row lg:items-center lg:gap-[2.4rem]">
          <div className="flex-ic gap-[1.8rem] lg:gap-[1.5rem]">
            <h3 className="whitespace-nowrap">관광사업자등록</h3>
            <p className="whitespace-nowrap font-light">
              제2019-000001호(국내여행업)
            </p>
          </div>
          <div className="flex-ic gap-[1.5rem]">
            <h3>통신판매업신고</h3>
            <p className="font-light">제2021-경북안동-0180호</p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:gap-[2.4rem]">
          <div className="flex-ic gap-[1.5rem]">
            <h3 className="whitespace-nowrap">소재지</h3>
            <p className="whitespace-nowrap  font-light">
              경상북도 안동시 풍천면 천년숲서로 7-19 화인비즈니스타운 705호
            </p>
          </div>
          <div className="flex-ic gap-[1.5rem]">
            <h3>TEL</h3>
            <p className="font-light">070-7018-9441</p>
          </div>
          <div className="flex-ic gap-[1.5rem]">
            <h3>E-MAIL</h3>
            <p className="font-light">tour-gb@altnm.co.kr</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
