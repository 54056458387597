import React, { forwardRef, useEffect, useRef } from "react";
import { ReactComponent as BlueLine } from "../static/icons/blue-line.svg";
import { ReactComponent as BlueLineMobile } from "../static/icons/blue-line-mobile.svg";
import { ReactComponent as Mall } from "../static/icons/mall-color.svg";
import { ReactComponent as Insta } from "../static/icons/insta-color.svg";
const blogBlack = require("../static/icons/naverblog-black.png");

type RefType = HTMLDivElement;
type PropsType = {};

const Partnership = forwardRef<RefType, PropsType>((props, ref) => {
  const mapEl = useRef(null);

  /**
   * @brief 네이버 지도
   * @detail 네이버 지도 API 를 이용해 지도 출력, 알트앤엠 회사의 위치를 항상 출력
   * FIXME: 현재 알트앤엠에서 제공해준 네이버 지도 API 키가 동작하지 않아 개인 API 키로 동작 중 해당 부분 알트앤엠 API로 .env 파일에서 바꿔놓을 것
   */
  useEffect(() => {
    const { naver } = window;
    if (!mapEl.current || !naver) return;

    const location = new naver.maps.LatLng(36.571801, 128.502817);
    const mapOptions: naver.maps.MapOptions = {
      center: location,
      zoom: 18,
      zoomControl: true,
      zoomControlOptions: {
        position: naver.maps.Position.TOP_RIGHT,
      },
    };

    const map = new naver.maps.Map(mapEl.current, mapOptions);
    new naver.maps.Marker({
      position: location,
      map,
    });
  }, []);

  return (
    <div
      className="flex-center w-full flex-col gap-[5rem] px-[6.4vw] pb-[11.54rem] lg:gap-[9.8rem] lg:px-[30rem] lg:pt-[15rem] "
      ref={ref}
    >
      <div className="flex-ic w-full flex-col gap-0 lg:gap-[4rem]">
        <BlueLine className="hidden lg:block" />
        <BlueLineMobile className="block lg:hidden" />
        <h1 className="mt-[1.6rem] font-['Pretendard'] text-[1.5rem] font-semibold leading-[1.8rem] text-[#6a6a6a] lg:mt-0 lg:text-[3.2rem] lg:font-bold lg:leading-[3.819rem] lg:text-[#434343]">
          제휴문의
        </h1>
        <p className="hidden font-['Pretendard'] text-[2rem] font-light leading-[3.1rem] text-[#474747] lg:block">
          알트앤엠 주식회사는 당신과 함께 합니다
        </p>
        <p className="mt-[2.4rem] block whitespace-pre-line text-center font-['Pretendard'] text-[1.4rem] font-light leading-[2rem]  text-[#6a6a6a] lg:hidden ">
          알트앤엠 주식회사는{"\n"}당신과 함께 합니다
        </p>
      </div>

      <div className="bg-[#ffffff] md:w-[64.1vw]  lg:w-[79.2rem]">
        <div ref={mapEl} className="min-h-[33.7rem] w-full" />
        <div className="box-border flex flex-col px-[5.34vw] py-[2.4rem] lg:p-[4.4rem]">
          <h1 className="font-['Pretendard'] text-[1.1rem] font-bold leading-[1.39rem] text-[#434343] lg:text-[1.747rem] lg:leading-[2.9rem]">
            알트앤엠 주식회사
          </h1>
          <div className="flex-ic gap-[1.5rem] lg:mt-[2.9rem]">
            <h3 className="mt-[1.38rem] font-['Pretendard'] text-[1.1rem] font-medium leading-[1.39rem] text-[#434343] lg:text-[1.747rem] lg:leading-[2.9rem]">
              소재지
            </h3>
            <p className="font-['Pretendard'] text-[1.1rem] font-normal leading-[1.39rem] text-[#434343] lg:text-[1.747rem] lg:leading-[2.9rem]">
              경상북도 안동시 풍천면 천년숲서로 29, 209,210호
            </p>
          </div>
          <div className="flex-ic mt-[1.14rem] gap-[4.6vw] lg:mt-[2.4rem] lg:gap-[6.1rem]">
            <div className="flex-ic gap-[2.4rem]">
              <h3 className="font-['Pretendard'] text-[1.1rem] font-medium leading-[1.39rem] text-[#434343] lg:text-[1.747rem] lg:leading-[2.9rem]">
                TEL
              </h3>
              <p className="whitespace-nowrap font-['Pretendard'] text-[1.1rem] font-normal leading-[1.39rem] text-[#434343] lg:text-[1.747rem] lg:leading-[2.9rem]">
                070-7018-9441
              </p>
            </div>
            <div className="flex-ic gap-[2.4rem]">
              <h3 className="whitespace-nowrap font-['Pretendard'] text-[1.1rem] font-medium leading-[1.39rem] text-[#434343] lg:text-[1.747rem] lg:leading-[2.9rem]">
                E-MAIL
              </h3>
              <p className="whitespace-nowrap font-['Pretendard'] text-[1.1rem] font-normal leading-[1.39rem] text-[#434343] lg:text-[1.747rem] lg:leading-[2.9rem]">
                tour-gb@altnm.co.kr
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-center gap-[2.1rem] lg:gap-[2.574rem]">
        <div>
          <a
            href="https://www.instagram.com/gb_travel/"
            target={"_blank"}
            rel="noreferrer"
          >
            <Insta
              width="25"
              height="25"
              fill="black"
              className="hidden lg:block"
            />
            <Insta
              width="16"
              height="16"
              fill="black"
              className="block lg:hidden"
            />
          </a>
        </div>

        <div>
          <a
            href="https://blog.naver.com/tour-gb"
            target={"_blank"}
            rel="noreferrer"
          >
            {/* <Blog fill="black" /> */}
            <img
              alt="네이버 블로그"
              src={blogBlack}
              className="hidden lg:block"
            />
            <img
              alt="네이버 블로그"
              src={blogBlack}
              className="block h-[16px] w-[16px] lg:hidden"
            />
          </a>
        </div>
        <div>
          <a href="https://tour-gb.co.kr/" target={"_blank"} rel="noreferrer">
            <Mall
              width="24"
              height="24"
              fill="black"
              className="hidden lg:block"
            />
            <Mall
              width="16"
              height="16"
              fill="black"
              className="block lg:hidden"
            />
          </a>
        </div>
      </div>
    </div>
  );
});

export default Partnership;
