import React, { useEffect } from "react";
import useScrollDirection from "../utils/useScrollDirection";

const TopMenuBar = (props: any) => {
  const { onMoveToElement, onMoveToElement2, onMoveToElement3 } = props;
  const scrollDirection = useScrollDirection();

  useEffect(() => {
    console.log(scrollDirection);
  }, [scrollDirection]);

  return (
    <div
      className={`flex-ic left fixed flex h-[12.9rem] w-full flex-col justify-between transition-all duration-500 md:h-[9.4rem] md:flex-row md:px-[8.38vw]  lg:px-[16.25%] ${
        scrollDirection === "down" ? "-top-[17rem]" : "top-0"
      } z-50 bg-[#ffffff] `}
    >
      <div
        className="cursor-pointer py-[1.7rem]"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <h1 className="whitespace-nowrap text-center font-['Pretendard'] text-[2rem] font-extrabold  text-[#1F2773] md:text-start md:text-[2.2rem] md:leading-[26.25px] lg:leading-[2.864rem]">
          알트앤엠 주식회사
        </h1>
      </div>

      <nav className="flex-ic w-full justify-center gap-[2.5rem] bg-[#F1F1F1] py-[2rem] md:justify-end  md:bg-[#ffffff] md:py-0 md:px-[0] lg:w-fit lg:justify-start lg:gap-[8rem]">
        <div className="flex-center cursor-pointer" onClick={onMoveToElement}>
          <p className="whitespace-nowrap font-['Pretendard'] text-[1.5rem] font-bold leading-[2.387rem] md:text-[1.8rem] lg:text-[2rem]">
            회사소개
          </p>
        </div>
        <div className="flex-center cursor-pointer" onClick={onMoveToElement2}>
          <p className="whitespace-nowrap font-['Pretendard'] text-[1.5rem] font-bold leading-[2.387rem] md:text-[2rem]">
            주요사업
          </p>
        </div>
        <div className="flex-center cursor-pointer" onClick={onMoveToElement3}>
          <p className="whitespace-nowrap font-['Pretendard'] text-[1.5rem] font-bold leading-[2.387rem] md:text-[2rem]">
            제휴문의
          </p>
        </div>
      </nav>
    </div>
  );
};

export default TopMenuBar;
