import React, { useState, useEffect } from "react";
import Footer from "./components/Footer";
import Introduce from "./pages/Introduce";
import TopMenuBar from "./components/TopMenuBar";
import MainBusiness from "./pages/MainBusiness";
import useMoveToDiv from "./utils/useMoveToDiv";
import Partnership from "./pages/Partnership";

//new branch

function App(props: any) {
  const { element, onMoveToElement } = useMoveToDiv();
  const { element: element2, onMoveToElement: onMoveToElement2 } =
    useMoveToDiv();
  const { element: element3, onMoveToElement: onMoveToElement3 } =
    useMoveToDiv();
  const [deviceType, setDeviceType] = useState<"mobile" | "tablet" | "desktop">(
    "desktop"
  );

  useEffect(() => {
    const detectDeviceType = () => {
      if (window.innerWidth <= 450) {
        setDeviceType("mobile");
      } else if (window.innerWidth < 1200) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };

    const handleResize = () => {
      if (window.innerWidth <= 450) {
        setDeviceType("mobile");
      } else if (window.innerWidth <= 1200) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };
    detectDeviceType();
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="App flex-center relative w-screen flex-col overflow-x-hidden bg-[#f7f7f7]">
      <TopMenuBar
        onMoveToElement={onMoveToElement}
        onMoveToElement2={onMoveToElement2}
        onMoveToElement3={onMoveToElement3}
      />
      <Introduce
        ref={element}
        onMoveToElement={onMoveToElement}
        deviceType={deviceType}
      />
      <MainBusiness ref={element2} deviceType={deviceType} />
      <Partnership ref={element3} />
      <Footer />
    </div>
  );
}

export default App;
