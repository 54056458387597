import Parser from "html-react-parser";
import { ReactComponent as BlueLine } from "../static/icons/blue-line.svg";
import { ReactComponent as ChevronRight } from "../static/icons/chevron-right.svg";
import { ReactComponent as ChevronRightMobile } from "../static/icons/chevron-right-mobile.svg";
import { ReactComponent as ChevronLeft } from "../static/icons/chevron-left.svg";
import { ReactComponent as ChevronLeftMobile } from "../static/icons/chevron-left-mobile.svg";
import { ReactComponent as Detail011Mobile } from "../static/images/detail-page1-mobile.svg";
import { ReactComponent as Detail5ChatbotMobile } from "../static/images/detail5-chatbot-mobile.svg";
import { ReactComponent as Detail5ChanelFeatureMobile } from "../static/images/detail5-chanelfeature-mobile.svg";
import { ReactComponent as Detail5ChatbotFeaturePc } from "../static/images/detail5-chatbotfeature-pc.svg";
import { ReactComponent as Detail5ChanelFeaturePc } from "../static/images/detail5-chanelfeature-pc.svg";

import { forwardRef, useEffect, useState, useRef } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase";

const Detail01_1 = require("../static/images/detail-page1.png");
const Detail01_2 = require("../static/images/detail-page1-2.png");
const Detail02_1 = require("../static/images/detail-page2-1.png");
const Detail2_grid1 = require("../static/images/detail2-grid1.png");
const Detail2_grid2 = require("../static/images/detail2-grid2.png");
const Detail2_grid3 = require("../static/images/detail2-grid3.png");
const Detail2_grid4 = require("../static/images/detail2-grid4.png");
const Detail2_grid5 = require("../static/images/detail2-grid5.png");
const Detail2_grid6 = require("../static/images/detail2-grid6.png");
const Detail2_grid7 = require("../static/images/detail2-grid7.png");
const Detail2_grid8 = require("../static/images/detail2-grid8.png");
const Detail2Mobile = require("../static/images/detail2-mobile.png");
const Detail4_1 = require("../static/images/detail4-1.png");
const Detail4_1_Mobile = require("../static/images/detail4-1-mobile.png");
const Detail4_2 = require("../static/images/detail4-2.png");
const Detail4_2_Mobile = require("../static/images/detail4-2-mobile.png");
const Detail5_1 = require("../static/images/detail5-1.png");
const Detail5_1_Mobile = require("../static/images/detail5-1-mobile.png");
const Detail5_2 = require("../static/images/detail5-2.png");

const Detail5_ChanelPhoto_Mobile = require("../static/images/detail5-chanelphoto-mobile.png");
const Detail6_1 = require("../static/images/detail6-1.png");
const Detail6_Mobile = require("../static/images/detail6-mobile.png");
const Detail7_1 = require("../static/images/detail7-1.png");
const Detail7_Mobile = require("../static/images/detail7-mobile.png");

type RefType = HTMLDivElement;
type PropsType = {
  deviceType: string;
};

const MainBusiness = forwardRef<RefType, PropsType>((props, ref) => {
  const [business, setBusiness] = useState<any>({ data: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const itemRefs = useRef<any>([]);
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const { deviceType } = props;

  /**
   * @brief 주요사업 동그라미를 클릭하면 해당 주요사업으로 이동
   * @param itemRefs.current Array 에 각각 주요사업의 div가 담겨있음
   * @param index 는 itemRefs.current 의 div 를 지정할 index
   */
  const handleOnMove = (index: number) => {
    if (itemRefs.current[index]) {
      itemRefs.current[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  /**
   * @brief 3가지 DeviceType 별로 슬라이더 1번 이동시 몇개의 요소들이 움직일 것인지 지정
   */
  const onClickNext = () => {
    if (deviceType === "desktop") {
      //29.47rem
      if (carouselRef.current) {
        if (carouselIndex >= business.data.length - 4) {
          setCarouselIndex(0);
        } else {
          setCarouselIndex((prev) => prev + 1);
        }
      }
    } else if (deviceType === "tablet") {
      if (carouselRef.current) {
        if (carouselIndex >= business.data.length - 3) {
          setCarouselIndex(0);
        } else {
          setCarouselIndex((prev) => prev + 1);
        }
      }
    } else if (deviceType === "mobile") {
      if (carouselRef.current) {
        if (carouselIndex >= business.data.length - 1) {
          setCarouselIndex(0);
        } else {
          setCarouselIndex((prev) => prev + 1);
        }
      }
    }
  };

  const onClickPrev = () => {
    //29.47rem
    if (carouselIndex < 0) {
      return;
    } else {
      setCarouselIndex((prev) => prev - 1);
    }
  };

  /**
   * @brief 주요사업 내용의 미리보기 (동그란 부분)를 Firebase 에서 불러옴
   * @detail 주요사업 내용의 내용은 Firebase 와 연동이 안 되어있는 상태 setBusiness 로 저장한 data 는 주요사업의 미리보기를 출력하기 위한 데이터
   * @detail Firebase 의 majorBusiness 컬렉션에서 수정/추가 가능하고 사이트에 반영됨
   * @detail 미완성 기능
   * FIXME: Firebase 와 연동은 필요없어진 부분이라 해당부분에서 오류 발생시 (MajorBusiness 미리보기 불러오지 못 함) Firbase 대신 로컬 데이터로 사용하면 좋을 듯
   */
  useEffect(() => {
    const unsubscribeBusiness = onSnapshot(
      doc(firestore, "majorBusiness", "items"),
      (doc) => {
        if (doc.data()) {
          try {
            const data = doc.data();
            if (data) {
              data.data.sort((a: any, b: any) => a.index - b.index);
              setBusiness(data);
            }
          } catch (error) {
            console.log(error);
          } finally {
            setIsLoading(false);
          }
        }
      }
    );

    return () => {
      unsubscribeBusiness();
    };
  }, []);

  useEffect(() => {
    if (business === undefined) return;
    console.log(business);
  }, [business]);

  /**
   * @brief 슬라이더에서 기기별로 다른 움직임을 보여주기위한 deviceType을 컴포넌트 마운트시에 저장
   * @detail deviceType 에 따라 carouselIndex의 변화에 따라 가로로 움직이는 비율을 조절, 조절 값은 동그라미의 크기 + gap 의 크기
   */

  useEffect(() => {
    if (carouselRef.current) {
      if (deviceType === "desktop") {
        carouselRef.current.style.marginLeft = `-${carouselIndex * 15.34}vw`;
      } else if (deviceType === "tablet") {
        carouselRef.current.style.marginLeft = `-${carouselIndex * 23}vw`;
      } else if (deviceType === "mobile") {
        carouselRef.current.style.marginLeft = `-${carouselIndex * 50.02}vw`;
      } else {
        //
      }
    }
  }, [carouselIndex, deviceType]);

  if (isLoading) return <div>loading...</div>;

  return (
    <div
      className="flex-center w-full flex-col py-[15rem] lg:gap-[30rem]"
      ref={ref}
    >
      <div className="flex-center w-full flex-col  gap-[1.6rem]  lg:gap-[4rem]">
        <BlueLine />
        <h2 className="font-['Pretendard'] text-[1.5rem] font-semibold leading-[1.8rem] text-[#6a6a6a] lg:text-[3.2rem] lg:leading-[3.818rem] lg:text-[#434343]">
          주요사업
        </h2>
        <p className="hidden font-['Pretendard'] text-[1.5rem] font-light leading-[1.8rem] text-[#6a6a6a] lg:block lg:text-[2rem] lg:leading-[3.1rem] lg:text-[#474747]">
          알트앤엠 주식회사에서 진행해온 주요 사업들을 소개합니다.
        </p>
        <div className="flex flex-col items-center bg-amber-300 lg:hidden">
          <p className="hidden font-['Pretendard'] text-[1.5rem] font-light leading-[1.8rem] text-[#6a6a6a] lg:block lg:text-[2rem] lg:leading-[3.1rem] lg:text-[#474747]">
            알트앤엠 주식회사에서 진행해온
          </p>
          <p className="hidden font-['Pretendard'] text-[1.5rem] font-light leading-[1.8rem] text-[#6a6a6a] lg:block lg:text-[2rem] lg:leading-[3.1rem] lg:text-[#474747]">
            주요 사업들을 소개합니다.
          </p>
        </div>

        {/* Temp Carousel S*/}
        <div className="relative flex w-full justify-center overflow-hidden md:mt-[140px] lg:h-[24.47rem] ">
          <div className="flex w-full items-center overflow-hidden px-[27.74vw] md:px-[16.83vw] lg:px-[19.53vw]">
            <div className="overflow-hidden">
              <div
                className="carouselAnimation flex w-full gap-[5.52vw] md:gap-[2.5vw] lg:gap-[2.6vw] lg:text-[4rem]"
                ref={carouselRef}
              >
                {business.data.map((item: any, index: number) => {
                  return (
                    <div
                      className=" flex-center h-[44.5vw] w-[44.5vw] flex-none shrink-0 cursor-pointer flex-col gap-[0.922rem] rounded-[50%] bg-[#ffffff] md:h-[20.5vw] md:w-[20.5vw] md:gap-[9px]  lg:h-[12.74vw] lg:w-[12.74vw]"
                      onClick={() => handleOnMove(index)}
                      key={index}
                    >
                      <h3 className="font-['Pretendard'] text-[2rem] font-bold leading-[2.5rem]  text-[#ea5758] md:text-[2.9rem] md:leading-[34.8px] lg:text-[2.9rem] lg:leading-[3.44rem]">
                        {item.index < 10 && "0"}
                        {item.index}
                      </h3>
                      <p className="whitespace-pre-line break-keep text-center font-['Pretendard'] text-[1.29rem] font-semibold leading-[1.82rem] text-[#434343] md:text-[1.8rem] md:leading-[25.38px] lg:text-[1.813rem] lg:leading-[2.538rem]">
                        {Parser(item.sliderTitle)}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <ChevronLeft
            className="put-top-center absolute hidden cursor-pointer lg:left-[15.63vw] lg:block "
            onClick={onClickPrev}
          />
          <ChevronRight
            className="put-top-center absolute  hidden cursor-pointer lg:right-[15.63vw] lg:block "
            onClick={onClickNext}
          />

          <ChevronLeftMobile
            className="put-top-center absolute left-[2.5rem] cursor-pointer  lg:hidden "
            onClick={onClickPrev}
          />
          <ChevronRightMobile
            className="put-top-center absolute right-[2.5rem] cursor-pointer lg:hidden "
            onClick={onClickNext}
          />
        </div>
        {/* Temp Carousel E*/}
      </div>
      {/* 01 */}

      <div
        className="mt-[15rem] flex w-full flex-col overflow-x-scroll px-[6.4vw] scrollbar-hide md:px-[8.38vw] lg:mt-0 lg:px-[15.63vw] lg:pt-[10rem]"
        ref={(el) => (itemRefs.current[0] = el)}
      >
        <div className="flex flex-col gap-[1.3rem] lg:gap-[2rem]">
          <h1 className=" font-['Pretendard'] text-[2.5rem] font-bold leading-[2.98rem] text-[#ea5758] md:text-[3.5rem] md:leading-[41.77px] lg:text-[5rem] lg:leading-[5.967rem]">
            01
          </h1>
          <h2 className=" font-['Pretendard'] text-[1.5rem] font-bold leading-[1.8rem] text-[#434343] md:text-[2.4rem] md:leading-[2.864rem]">
            바우처/티켓 운영 플랫폼 Vmaker 개발 및 운영
          </h2>
          <p className="font-normaltext-[#7d7d7d] hidden whitespace-pre-line break-keep font-['Pretendard'] text-[1.8rem] leading-[3rem] text-[#7d7d7d] md:block">
            자체 개발한 모바일 바우처/티켓 운영 플랫폼인 브이메이커(Vmaker)는
            등록된 시설의 티켓, 이용권 등의 상품을{"\n"} 오픈마켓, 소셜커머스
            전용몰 등의 판매채널에 연동하여 판매와 운영을 대행하는 모델로 실시간
            주문,사용,정산 데이터를 확인할 수 있습니다.
          </p>
          <p className="self-start whitespace-pre-line break-keep text-left font-['Pretendard'] text-[1.2rem] font-light leading-[2rem] text-[#7d7d7d] md:hidden ">
            자체 개발한 모바일 바우처/티켓 운영 플랫폼인 브이메이커 (Vmaker) 는
            등록된 시설의 티켓, 이용권 등의 상품을 오픈마켓, 소셜커머스 전용몰
            등의 판매채널에 연동하여 판매와 운영을 대행하는 모델로 실시간
            주문,사용,정산 데이터를 확인할 수 있습니다.
          </p>
        </div>

        <div className="flex-center mt-[10rem] w-full flex-col lg:mt-[14.13rem]">
          <img alt="" src={Detail01_1} className="hidden md:block" />
          <Detail011Mobile className="block md:hidden" />
        </div>

        <div className=" mt-[12.5rem]  w-full overflow-x-scroll  lg:mt-[25rem] lg:overflow-hidden ">
          <div className="w-[137rem] md:w-full lg:w-full lg:overflow-hidden lg:overflow-x-scroll">
            <img alt="" src={Detail01_2} className="h-full" />
          </div>
        </div>
      </div>

      {/* 02 */}
      <div
        className="mt-[15rem] flex w-full flex-col gap-[5rem]  md:px-[8.38vw] lg:mt-0 lg:gap-0 lg:px-[15.63vw]"
        ref={(el) => (itemRefs.current[1] = el)}
      >
        <div className=" flex w-full flex-col gap-[1.3rem] px-[6.4vw] md:px-0 lg:gap-[2rem] lg:px-0 ">
          <h1 className="self-end font-['Pretendard'] text-[2.5rem] font-bold leading-[2.98rem] text-[#ea5758] md:text-[3.5rem] md:leading-[41.77px] lg:text-[5rem] lg:leading-[5.967rem]">
            02
          </h1>
          <h2 className=" self-end font-['Pretendard'] text-[1.5rem] font-bold leading-[1.8rem] text-[#434343] md:text-[2.4rem] md:leading-[2.864rem]">
            경북e누리
          </h2>
          <p className="hidden self-end whitespace-pre-line break-keep text-right font-['Pretendard'] text-[1.8rem] font-normal leading-[3rem] text-[#7d7d7d] xl:block">
            2019년에 브랜딩한 경북나드리 사업은 경상북도의 관광지를 널리 알리고
            모든 혜택은 관광객에게 할인으로 돌려줄 수 있는 사업으로서 2019년부터
            2022년까지{"\n"} 지속되었습니다. 2021년부터 경북e누리라는 명칭으로
            변경되었고, 기획전 및 할인전을 통하여 지속적으로 경상북도의 관광을
            책임지고 있습니다.
          </p>
          <p className="hidden self-end whitespace-pre-line  break-keep text-right font-['Pretendard'] text-[1.8rem] font-normal leading-[3rem] text-[#7d7d7d] lg:block xl:hidden">
            2019년에 브랜딩한 경북나드리 사업은 경상북도의 관광지를 널리 알리고
            모든 혜택은 관광객에게 할인으로 돌려줄 수 있는 사업으로서 2019년부터
            2022년까지 지속되었습니다. 2021년부터 경북e누리라는 명칭으로
            변경되었고, 기획전 및 할인전을 통하여 지속적으로 경상북도의 관광을
            책임지고 있습니다.
          </p>
          <p className="block self-end whitespace-pre-line break-keep text-right font-['Pretendard'] text-[1.4rem] font-light leading-[2rem] text-[#7d7d7d] md:hidden">
            2019년에 브랜딩한 경북나드리 사업은 경상북도의 관광지를 널리 알리고
            모든 혜택은 관광객에게 할인으로 돌려줄 수 있는 사업으로서 2019년부터
            2022년까지 지속되었습니다. 2021년부터 경북e누리라는 명칭으로
            변경되었고, 기획전 및 할인전을 통하여 지속적으로 경상북도의 관광을
            책임지고 있습니다.
          </p>
        </div>
        <div className="w-full lg:mt-[11rem]">
          <img alt="" src={Detail02_1} className="hidden md:block" />
          <img
            alt=""
            src={Detail2Mobile}
            className="block h-full w-full md:hidden"
          />
        </div>
      </div>
      {/* 03 S*/}
      <div
        className="mt-[15rem] flex w-full flex-col gap-[5rem] px-[6.4vw] md:px-[8.38vw] lg:mt-0 lg:gap-0 lg:px-[15.63vw]"
        ref={(el) => (itemRefs.current[2] = el)}
      >
        <div className="flex w-full flex-col gap-[1.3rem] lg:gap-[2rem]">
          <h1 className=" font-['Pretendard'] text-[2.5rem] font-bold leading-[2.98rem] text-[#ea5758] md:text-[3.5rem] md:leading-[41.77px] lg:text-[5rem] lg:leading-[5.967rem]">
            03
          </h1>
          <h2 className=" font-['Pretendard'] text-[1.5rem] font-bold leading-[1.8rem] text-[#434343] md:text-[2.4rem] md:leading-[2.864rem]">
            하이스토리 경북
          </h2>
          <p className="hidden whitespace-pre-line  break-keep font-['Pretendard'] text-[1.8rem] font-normal leading-[3rem] text-[#7d7d7d] md:block ">
            하이스토리 경북 사업은 경상북도 내 3대 문화권(신라, 가야, 유교)
            활성화를 위한 관광진흥사업으로,{"\n"} 경상북도를 시군 간 문화·관광
            연계성에 따라 8개 테마로 묶어 테마 상품 개발 및 관광 콘텐츠를 체험할
            수 있도록 기획·운영하였습니다.
          </p>
          <p className="break-kepp block whitespace-pre-line text-left font-['Pretendard'] text-[1.2rem] font-light leading-[2rem] text-[#7d7d7d] md:hidden ">
            하이스토리 경북 사업은 경상북도 내 3대 문화권(신라, 가야, 유교)
            활성화를 위한 관광진흥사업으로, 경상북도를 시군 간 문화·관광
            연계성에 따라 8개 테마로 묶어 테마 상품 개발 및 관광 콘텐츠를 체험할
            수 있도록 기획·운영하였습니다.
          </p>
        </div>

        <div className="flex w-full flex-row gap-[1rem] overflow-scroll lg:mt-[11rem] lg:grid lg:grid-cols-4 lg:grid-rows-2 lg:gap-[1.2rem] lg:overflow-hidden">
          <div className="relative aspect-[1/1.12] shrink-0">
            <img
              alt=""
              src={Detail2_grid1}
              className="relative h-full w-full object-cover lg:absolute "
            />
            <div className="absolute top-[55.6%] z-10 flex flex-col gap-[0.829rem] px-[8.3%]">
              <h1 className="font-['Pretendard'] text-[1.14rem] font-semibold leading-[13.61px] text-[#ffffff] xl:text-[1.363rem] xl:leading-[1.626rem]">
                2권역/김천
              </h1>
              <h2 className="font-['Pretendard'] text-[1.47rem] font-bold leading-[17.61px] text-[#ffffff] xl:text-[1.763rem] xl:leading-[2rem]">
                김천 스탬프랠리
              </h2>
            </div>
            <div className="absolute top-[77.6%] z-10 px-[8.3%]">
              <p className="whitespace-pre-line break-keep font-['Pretendard'] text-[1.237rem] font-normal leading-[15.43px] text-[#d8d8d8]   xl:text-[1.478rem] xl:leading-[1.844rem]">
                직지사 권역에 구축된 사명대사공원일대를 산책하며 스마트폰에 찍는
                스탬프 랠리 구현
              </p>
            </div>
            <div className="absolute top-0 h-full w-full bg-gradient-to-t from-[rgba(0,0,0,0.8)] via-[rgba(0,0,0,0.05)] to-transparent"></div>
          </div>
          <div className="relative aspect-[1/1.12] shrink-0">
            <img
              alt=""
              src={Detail2_grid2}
              className="relative h-full w-full object-cover lg:absolute "
            />
            <div className="absolute top-[55.6%] z-10 flex flex-col gap-[0.829rem] px-[8.3%]">
              <h1 className="font-['Pretendard'] text-[1.14rem] font-semibold leading-[13.61px] text-[#ffffff] md:text-[1.363rem] md:leading-[1.626rem]">
                4권역/예천
              </h1>
              <h2 className="whitespace-pre-line break-keep font-['Pretendard'] text-[1.47rem] font-bold leading-[17.61px] text-[#ffffff] xl:text-[1.763rem] xl:leading-[2rem]">
                삼강주막 미션게임{"\n"} ‘잃어버린 거문고를 찾아라'
              </h2>
            </div>
            <div className="absolute top-[77.6%] z-10 px-[8.3%]">
              <p className="whitespace-pre-line break-keep font-['Pretendard'] text-[1.237rem] font-normal leading-[15.43px] text-[#d8d8d8] xl:text-[1.478rem] xl:leading-[1.844rem]">
                삼강문화단지와 강문화전시관을 둘러보며 미션을 해결하는 야외
                미션체험 게임
              </p>
            </div>
            <div className="absolute top-0 h-full w-full bg-gradient-to-t from-[rgba(0,0,0,0.8)] via-[rgba(0,0,0,0.05)] to-transparent"></div>
          </div>
          <div className="relative aspect-[1/1.12] shrink-0">
            <img
              alt=""
              src={Detail2_grid3}
              className="relative h-full w-full object-cover lg:absolute "
            />
            <div className="absolute top-[55.6%] z-10 flex flex-col gap-[0.829rem] px-[8.3%]">
              <h1 className="font-['Pretendard'] text-[1.14rem] font-semibold leading-[13.61px] text-[#ffffff] md:text-[1.363rem] md:leading-[1.626rem]">
                4권역/안동
              </h1>
              <h2 className="whitespace-pre-line font-['Pretendard'] text-[1.47rem] font-bold leading-[17.61px] text-[#ffffff] xl:text-[1.763rem] xl:leading-[2rem]">
                선성현문화단지 미션게임{"\n"} ‘미래도시 안틀란티스'
              </h2>
            </div>
            <div className="absolute top-[77.6%] z-10 px-[8.3%]">
              <p className="whitespace-pre-line break-keep font-['Pretendard'] text-[1.237rem] font-normal leading-[15.43px] text-[#d8d8d8] lg:text-[1rem] xl:text-[1.478rem] xl:leading-[1.844rem]">
                안동댐 수몰에 대한 가상의 시나리오를 제시하고 미션을 수행하는
                선성현문화단지와 예기마을 야외 미션 체험 게임
              </p>
            </div>
            <div className="absolute top-0 h-full w-full bg-gradient-to-t from-[rgba(0,0,0,0.8)] via-[rgba(0,0,0,0.05)] to-transparent"></div>
          </div>
          <div className="relative aspect-[1/1.12] shrink-0">
            <img
              alt=""
              src={Detail2_grid4}
              className="relative h-full w-full object-cover lg:absolute "
            />
            <div className="absolute top-[55.6%] z-10 flex flex-col gap-[0.829rem] px-[8.3%]">
              <h1 className="font-['Pretendard'] text-[1.14rem] font-semibold leading-[13.61px] text-[#ffffff] md:text-[1.363rem] md:leading-[1.626rem]">
                4권역/상주
              </h1>
              <h2 className="font-['Pretendard'] text-[1.47rem] font-bold leading-[17.61px] text-[#ffffff] xl:text-[1.763rem] xl:leading-[2rem]">
                경천대 미션게임 '경천대 비사'
              </h2>
            </div>
            <div className="absolute top-[77.6%] z-10 px-[8.3%]">
              <p className="whitespace-pre-line break-keep font-['Pretendard'] text-[1.237rem] font-normal leading-[15.43px] text-[#d8d8d8] xl:text-[1.478rem] xl:leading-[1.844rem]">
                모바일 앱에 제시되는 미션을 해결하면서 상주 역사를 이해하는 야외
                미션 체험 게임
              </p>
            </div>
            <div className="absolute top-0 h-full w-full bg-gradient-to-t from-[rgba(0,0,0,0.8)] via-[rgba(0,0,0,0.05)] to-transparent"></div>
          </div>
          <div className="relative aspect-[1/1.12] shrink-0">
            <img
              alt=""
              src={Detail2_grid5}
              className="relative h-full w-full object-cover lg:absolute "
            />
            <div className="absolute top-[55.6%] z-10 flex flex-col gap-[0.829rem] px-[8.3%]">
              <h1 className="font-['Pretendard'] text-[1.14rem] font-semibold leading-[13.61px] text-[#ffffff] xl:text-[1.363rem] xl:leading-[1.626rem]">
                4권역/문경
              </h1>
              <h2 className="font-['Pretendard'] text-[1.47rem] font-bold leading-[17.61px] text-[#ffffff] xl:text-[1.763rem] xl:leading-[2rem]">
                은성사진관
              </h2>
            </div>
            <div className="absolute top-[77.6%] z-10 px-[8.3%]">
              <p className="whitespace-pre-line break-keep font-['Pretendard'] text-[1.237rem] font-normal leading-[15.43px] text-[#d8d8d8] xl:text-[1.478rem] xl:leading-[1.844rem]">
                석탄박물관 사택촌 내 추억의 사진관을 구축하여 관광객들이 셀프
                촬영 체험
              </p>
            </div>
            <div className="absolute top-0 h-full w-full bg-gradient-to-t from-[rgba(0,0,0,0.8)] via-[rgba(0,0,0,0.05)] to-transparent"></div>
          </div>
          <div className="relative aspect-[1/1.12] shrink-0">
            <img
              alt=""
              src={Detail2_grid6}
              className="relative h-full w-full object-cover lg:absolute "
            />
            <div className="absolute top-[55.6%] z-10 flex flex-col gap-[0.829rem] px-[8.3%]">
              <h1 className="font-['Pretendard'] text-[1.14rem] font-semibold leading-[13.61px] text-[#ffffff] xl:text-[1.363rem] xl:leading-[1.626rem]">
                5권역/영주
              </h1>
              <h2 className="whitespace-pre-line font-['Pretendard'] text-[1.47rem] font-bold leading-[17.61px] text-[#ffffff] xl:text-[1.763rem] xl:leading-[2rem]">
                ‘21세기 선비를 만나다'{"\n"} 다큐멘터리
              </h2>
            </div>
            <div className="absolute top-[77.6%] z-10 px-[8.3%]">
              <p className="whitespace-pre-line break-keep font-['Pretendard'] text-[1.237rem] font-normal leading-[15.43px] text-[#d8d8d8] xl:text-[1.478rem] xl:leading-[1.844rem]">
                선비 관련 다큐 제작 지원
              </p>
            </div>
            <div className="absolute top-0 h-full w-full bg-gradient-to-t from-[rgba(0,0,0,0.8)] via-[rgba(0,0,0,0.05)] to-transparent"></div>
          </div>
          <div className="relative aspect-[1/1.12] shrink-0">
            <img
              alt=""
              src={Detail2_grid7}
              className="relative h-full w-full object-cover lg:absolute "
            />
            <div className="absolute top-[55.6%] z-10 flex flex-col gap-[0.829rem] px-[8.3%]">
              <h1 className="font-['Pretendard'] text-[1.14rem] font-semibold leading-[13.61px] text-[#ffffff] xl:text-[1.363rem] xl:leading-[1.626rem]">
                8권역/경주
              </h1>
              <h2 className="whitespace-pre-line font-['Pretendard'] text-[1.47rem] font-bold leading-[17.61px] text-[#ffffff] xl:text-[1.763rem] xl:leading-[2rem]">
                경주 스탬프랠리
              </h2>
            </div>
            <div className="absolute top-[77.6%] z-10 px-[8.3%]">
              <p className="whitespace-pre-line break-keep font-['Pretendard'] text-[1.237rem] font-normal leading-[15.43px] text-[#d8d8d8] xl:text-[1.478rem] xl:leading-[1.844rem]">
                경주화랑마을 내 스탬프 투어
              </p>
            </div>
            <div className="absolute top-0 h-full w-full bg-gradient-to-t from-[rgba(0,0,0,0.8)] via-[rgba(0,0,0,0.05)] to-transparent"></div>
          </div>
          <div className="relative  hidden aspect-[1/1.12] shrink-0  items-center  justify-center px-[13.67%] lg:flex">
            <img
              alt=""
              src={Detail2_grid8}
              className="relaive hidden h-full w-full object-contain lg:block "
            />
          </div>
        </div>
      </div>
      {/* 03 E*/}
      {/* WHITEBOX S*/}
      <div className="flex-ic w-full flex-col lg:gap-[30rem] lg:rounded-tl-[30rem] lg:rounded-br-[30rem] lg:bg-[#ffffff] lg:px-0 lg:pb-[37rem] lg:pt-[30rem] ">
        {/* 04 S */}
        <div
          className="mt-[15rem] flex w-full flex-col gap-[5rem] px-[6.4vw] md:px-[8.38vw] lg:mt-0 lg:gap-[15.5rem] lg:px-[15.63vw]"
          ref={(el) => (itemRefs.current[3] = el)}
        >
          <div className="flex w-full flex-col gap-[1.3rem] lg:gap-[2rem]">
            <h1 className="self-end font-['Pretendard'] text-[2.5rem] font-bold leading-[2.98rem] text-[#ea5758] md:text-[3.5rem] md:leading-[41.77px] lg:text-[5rem] lg:leading-[5.967rem]">
              04
            </h1>
            <h2 className="self-end font-['Pretendard'] text-[1.5rem] font-bold leading-[1.8rem] text-[#434343] md:text-[2.4rem] md:leading-[2.864rem]">
              관광 콘텐츠 및 체험 관광 프로그램 개발
            </h2>
            <p className="hidden self-end whitespace-pre-line break-keep text-right font-['Pretendard'] text-[1.8rem] font-normal leading-[3rem] text-[#7d7d7d] md:block ">
              관광객에게 관광시설의 의미를 보다 쉽고 재밌게 알려줄 수 있도록,
              스마트폰과 현장을 연결할 수 있는 기능의 관광 콘텐츠를 개발하였고,
              {"\n"}
              연인·가족·아이 할 것 없이 관광시설을 즐길 수 있도록 구성했습니다.
              또한 해당 콘텐츠를 통해 소셜마케팅 및 프로모션도 연계하여
              운영했습니다.
            </p>
            <p className="block whitespace-pre-line break-keep text-right font-['Pretendard'] text-[1.2rem] font-light leading-[2rem] text-[#7d7d7d] md:hidden ">
              관광객에게 관광시설의 의미를 보다 쉽고 재밌게 알려줄 수 있도록,
              스마트폰과 현장을 연결할 수 있는 기능의 관광 콘텐츠를 개발하였고,
              연인·가족·아이 할 것 없이 관광시설을 즐길 수 있도록 구성했습니다.
              또한 해당 콘텐츠를 통해 소셜마케팅 및 프로모션도 연계하여
              운영했습니다.
            </p>
          </div>

          <div className="flex-ic w-full flex-col gap-[4.7rem] ">
            <div className="hidden md:block">
              <img alt="" src={Detail4_1} />
            </div>
            <div className="w-full overflow-x-scroll md:hidden">
              <div className="w-[75.2rem]">
                <img alt="" src={Detail4_1_Mobile} className="w-[75.2rem]" />
              </div>
            </div>

            <div className="flex-ic w-full flex-col gap-[1.5rem] lg:gap-[2rem]">
              <h1 className="font-['Pretendard'] text-[1.5rem] font-semibold leading-[1.8rem] text-[#434343] lg:text-[2rem] lg:font-bold lg:leading-[2.387rem] lg:text-[#696969]">
                모바일 웹 페이지를 이용한 관광지 미션 게임
              </h1>
              <p className="text-center font-['Pretendard'] text-[1.4rem] font-light leading-[1.7rem] text-[#7d7d7d] lg:text-[1.7rem] lg:font-normal lg:leading-[2.029rem]">
                관광객이 관광지 내 시설들을 두루 둘러볼 수 있도록 구성된 야외
                미션 게임으로, 게임 결과를 SNS에 공유하는 프로모션과 연계하여
                운영하였습니다.
              </p>
            </div>
          </div>

          <div className="flex-ic w-full flex-col gap-[4.7rem] ">
            <div className="hidden md:block">
              <img alt="" src={Detail4_2} />
            </div>
            <div className="w-full overflow-x-scroll md:hidden">
              <div className="w-[75.2rem]">
                <img alt="" src={Detail4_2_Mobile} className="w-[75.2rem]" />
              </div>
            </div>

            <div className="flex-ic w-full flex-col gap-[1.5rem] lg:gap-[2rem]">
              <h1 className="font-['Pretendard'] text-[1.5rem] font-semibold leading-[1.8rem] text-[#434343] lg:text-[2rem] lg:font-bold lg:leading-[2.387rem] lg:text-[#696969]">
                스마트폰과 스마트 스탬프를 이용한 스탬프 랠리
              </h1>
              <p className="hidden whitespace-pre-line break-keep text-center font-['Pretendard'] text-[1.4rem] font-light leading-[1.7rem] text-[#7d7d7d] lg:text-[1.7rem] lg:font-normal lg:leading-[2.029rem] xl:block">
                관광객이 플랫폼 내 주요 포인트를 방문하여 관련 콘텐츠를 확인하고
                방문 기록을 남길 수 있도록 구성된 스탬프 랠리로, 고객의 이동
                동선 안내, 콘텐츠 소개도 제공하며{"\n"} 필요한 경우 고객의 정보
                및 마케팅 동의 등을 얻을 수도 있습니다. 한 플랫폼 내 투어 뿐만
                아니라 지역 내, 권역 전체를 엮어 스탬프 랠리도 가능합니다.
              </p>
              <p className="block whitespace-pre-line break-keep text-center font-['Pretendard'] text-[1.4rem] font-light leading-[1.7rem] text-[#7d7d7d] lg:text-[1.4rem] lg:font-normal lg:leading-[2.029rem] xl:hidden">
                관광객이 플랫폼 내 주요 포인트를 방문하여 관련 콘텐츠를 확인하고
                방문 기록을 남길 수 있도록 구성된 스탬프 랠리로, 고객의 이동
                동선 안내, 콘텐츠 소개도 제공하며{"\n"} 필요한 경우 고객의 정보
                및 마케팅 동의 등을 얻을 수도 있습니다. 한 플랫폼 내 투어 뿐만
                아니라 지역 내, 권역 전체를 엮어 스탬프 랠리도 가능합니다.
              </p>
            </div>
          </div>
        </div>
        {/* 04 E */}

        {/* 05 S */}
        <div
          className="mt-[15rem] flex w-full flex-col  px-[6.4vw] md:px-[8.38vw] lg:mt-0 lg:gap-[15rem] lg:px-[15.63vw]"
          ref={(el) => (itemRefs.current[4] = el)}
        >
          <div className="flex w-full flex-col gap-[1.3rem] lg:gap-[2rem]">
            <h1 className=" font-['Pretendard'] text-[2.5rem] font-bold leading-[2.98rem] text-[#ea5758] md:text-[3.5rem] md:leading-[41.77px] lg:text-[5rem] lg:leading-[5.967rem]">
              05
            </h1>
            <h2 className=" font-['Pretendard'] text-[1.5rem] font-bold leading-[1.8rem] text-[#434343] md:text-[2.4rem] md:leading-[2.864rem]">
              관광 및 축제 활성화를 위한 카카오채널
            </h2>
            <p className="hidden whitespace-pre-line break-keep font-['Pretendard'] text-[1.8rem] font-normal leading-[3rem] text-[#7d7d7d] xl:block  ">
              지역을 방문하는 관광객, 방문 계획을 수립하는 관광객들에게
              관광·교통·시설·축제 등의 정보를 실시간으로 제공할 수 있는 AI 챗봇
              기능이 추가된{"\n"} 카카오채널과 관광 관련 정보를 관리하고
              카카오채널 콘텐츠로 활용할 수 있는 관광 정보 포탈을 구축 및
              운영하고 있습니다.
            </p>
            <p className="hidden whitespace-pre-line break-keep font-['Pretendard'] text-[1.8rem] font-normal leading-[3rem] text-[#7d7d7d] md:block xl:hidden  ">
              지역을 방문하는 관광객, 방문 계획을 수립하는 관광객들에게
              관광·교통·시설·축제 등의 정보를 실시간으로 제공할 수 있는 AI 챗봇
              기능이 추가된 카카오채널과 관광 관련 정보를 관리하고 카카오채널
              콘텐츠로 활용할 수 있는 관광 정보 포탈을 구축 및 운영하고
              있습니다.
            </p>
            <p className="block whitespace-pre-line break-keep  text-left font-['Pretendard'] text-[1.2rem] font-light leading-[2rem] text-[#7d7d7d] md:hidden ">
              지역을 방문하는 관광객, 방문 계획을 수립하는 관광객들에게
              관광·교통·시설·축제 등의 정보를 실시간으로 제공할 수 있는 AI 챗봇
              기능이 추가된 카카오채널과 관광 관련 정보를 관리하고 카카오채널
              콘텐츠로 활용할 수 있는 관광 정보 포탈을 구축 및 운영하고
              있습니다.
            </p>
          </div>

          <div className="flex-ic w-full flex-col gap-[5.5rem]">
            <div className="hidden  w-full flex-col items-center gap-[2.9rem] lg:flex">
              <h1 className="font-['Pretendard'] text-[2rem] font-semibold leading-[2.387rem] text-[#696969]">
                AI 챗봇 기능
              </h1>
              <Detail5ChatbotFeaturePc />
            </div>
            <div className="mt-[8rem] flex flex-col items-center  justify-center lg:hidden">
              <Detail5ChatbotMobile />
            </div>

            <div className="block  w-full overflow-x-scroll  lg:hidden">
              <div className="w-[81.4rem] overflow-x-scroll md:w-full">
                <img alt="" src={Detail5_1_Mobile} className="w-[81.4rem]" />
              </div>
            </div>

            <div className="hidden w-full justify-center lg:flex ">
              <img alt="" src={Detail5_1} />
            </div>
          </div>

          <div className="lg:flex-ic mt-[3rem] hidden w-full flex-col gap-[5.5rem]">
            <div className=" flex w-full flex-col items-center gap-[2.9rem]">
              <h1 className="font-['Pretendard'] text-[2rem] font-semibold leading-[2.387rem] text-[#696969]">
                채널 기능
              </h1>
              <Detail5ChanelFeaturePc />
            </div>

            <div>
              <img alt="" src={Detail5_2} />
            </div>
          </div>

          <div className="mt-[10rem] block w-full md:flex md:flex-col md:items-center lg:hidden">
            <Detail5ChanelFeatureMobile />
            <img
              alt=""
              src={Detail5_ChanelPhoto_Mobile}
              className="mt-[5rem] h-full w-full md:hidden"
            />
            <img alt="" src={Detail5_2} className="mt-[68px] hidden md:block" />
          </div>
        </div>
        {/* 05 E */}
      </div>
      {/* WHITEBOX E*/}
      {/* 06 S */}
      <div
        className="mt-[15rem] flex w-full flex-col gap-[5rem] px-[6.4vw] md:px-[8.38vw] lg:mt-0 lg:gap-[15rem] lg:px-[15.63vw]"
        ref={(el) => (itemRefs.current[5] = el)}
      >
        <div className="flex w-full flex-col gap-[1.3rem] lg:gap-[2rem]">
          <h1 className="self-end font-['Pretendard'] text-[2.5rem] font-bold leading-[2.98rem] text-[#ea5758] md:text-[3.5rem] md:leading-[41.77px] lg:text-[5rem] lg:leading-[5.967rem]">
            06
          </h1>
          <h2 className="self-end font-['Pretendard'] text-[1.5rem] font-bold leading-[1.8rem] text-[#434343] md:text-[2.4rem] md:leading-[2.864rem]">
            선비이야기 여행 여행자 플랫폼 구축 및 판매
          </h2>
          <p className="hidden self-end whitespace-pre-line break-keep text-end font-['Pretendard'] text-[1.8rem] font-normal leading-[3rem] text-[#7d7d7d] xl:block">
            ‘선비이야기여행’ 권역(대구, 안동, 영주, 문경) 소재 관광 시설에 체험
            프로그램을 구성하여, 개별 관광객을 대상으로 편리한 관광안내 서비스를
            제공하는 사업입니다.{"\n"} 권역별 연계 구성 및 통합 마케팅과 종합
            관광 안내시스템을 통한 관광객 유치, 증대 및 관광 소비 극대화와
            체류시간 증대를 도모하여, 지역경제 활성화를 이루고 있습니다.
          </p>
          <p className="hidden self-end whitespace-pre-line break-keep text-end font-['Pretendard'] text-[1.8rem] font-normal leading-[3rem] text-[#7d7d7d] md:block xl:hidden">
            ‘선비이야기여행’ 권역(대구, 안동, 영주, 문경) 소재 관광 시설에 체험
            프로그램을 구성하여, 개별 관광객을 대상으로 편리한 관광안내 서비스를
            제공하는 사업입니다. 권역별 연계 구성 및 통합 마케팅과 종합 관광
            안내시스템을 통한 관광객 유치, 증대 및 관광 소비 극대화와 체류시간
            증대를 도모하여, 지역경제 활성화를 이루고 있습니다.
          </p>
          <p className="block whitespace-pre-line break-keep text-right font-['Pretendard'] text-[1.2rem] font-light leading-[2rem] text-[#7d7d7d] md:hidden ">
            ‘선비이야기여행’ 권역(대구, 안동, 영주, 문경) 소재 관광 시설에 체험
            프로그램을 구성하여, 개별 관광객을 대상으로 편리한 관광안내 서비스를
            제공하는 사업입니다. 권역별 연계 구성 및 통합 마케팅과 종합 관광
            안내시스템을 통한 관광객 유치, 증대 및 관광 소비 극대화와 체류시간
            증대를 도모하여, 지역경제 활성화를 이루고 있습니다.
          </p>
        </div>

        <div className="hidden md:block">
          <img alt="" src={Detail6_1} />
        </div>
        <div className="block w-full overflow-x-scroll md:hidden">
          <div className="w-[104.4rem]">
            <img alt="" src={Detail6_Mobile} />
          </div>
        </div>
      </div>
      {/* 06 E */}
      {/* 07 S */}
      <div
        className="mt-[15rem] flex w-full flex-col gap-[5rem] lg:mt-0 lg:gap-[10.2rem] lg:px-[15.63vw] "
        ref={(el) => (itemRefs.current[6] = el)}
      >
        <div className="flex w-full flex-col gap-[1.3rem] px-[6.4vw] md:px-[8.38vw] lg:gap-[2rem] lg:px-0">
          <h1 className=" font-['Pretendard'] text-[2.5rem] font-bold leading-[2.98rem] text-[#ea5758] md:text-[3.5rem] md:leading-[41.77px] lg:text-[5rem] lg:leading-[5.967rem]">
            07
          </h1>
          <h2 className=" font-['Pretendard'] text-[1.5rem] font-bold leading-[1.8rem] text-[#434343] md:text-[2.4rem] md:leading-[2.864rem]">
            2022 경북 참 웰니스 페스티벌
          </h2>
          <p className="hidden whitespace-pre-line break-keep font-['Pretendard']  text-[1.8rem] font-normal leading-[3rem] text-[#7d7d7d] md:block ">
            2022 경북 참 웰니스 페스티벌은 웰니스 관광 중심지 경북의
            힐링·치유·건강 관광자원을 홍보하기 위해{"\n"} 5개 거점시설과 경북
            웰니스 관광지가 참여하여 2022.10.7(금)~10.10(월) 4일간 경북 영주
            선비세상에서 개최된 페스티벌입니다.
          </p>
          <p className="block whitespace-pre-line break-keep text-left  font-['Pretendard'] text-[1.2rem] font-light leading-[2rem] text-[#7d7d7d] md:hidden ">
            2022 경북 참 웰니스 페스티벌은 웰니스 관광 중심지 경북의
            힐링·치유·건강 관광자원을 홍보하기 위해 5개 거점시설과 경북 웰니스
            관광지가 참여하여 2022.10.7(금)~10.10(월) 4일간 경북 영주
            선비세상에서 개최된 페스티벌입니다.
          </p>
        </div>

        <div className="flex w-full flex-col gap-[4.555rem] md:px-[8.38vw] lg:px-0 ">
          <div>
            <img alt="" src={Detail7_1} className="hidden md:block" />
            <img
              alt=""
              src={Detail7_Mobile}
              className="block w-full md:hidden"
            />
          </div>
          <div className="hidden md:block">
            <p className="whitespace-pre-line break-keep font-['Pretendard'] text-[1.8rem] font-normal leading-[3rem] text-[#7d7d7d] md:text-[1.8rem] ">
              경북 참 웰니스 페스티벌은 웰빙 well-being + 행복 happiness + 건강
              fitness의 합성어로 신체와 정신은 물론 사회적으로 건강한 상태를
              뜻하는 웰니스의 의미를 담은 페스티벌입니다. 당사는 경상북도의 5개
              시/군의 특색을 담은 체험형 프로그램을 기획하고 페스티벌 기간 동안
              웰니스 관광지 및 관광자원 오프라인 홍보 및 이벤트 부스 운영
              진행하였으며 SNS 홍보/온라인 상품 판매/라이브 커머스 등 온라인
              홍보와 페스티벌 기간 내에 체험형 프로그램 기획하고 홍보부스
              운영·보조·설치/온·오프라인 판매 및 홍보하였습니다.
            </p>
          </div>
        </div>
      </div>
      {/* 07 E */}
      {/* TEST S */}

      {/* TEST E */}
    </div>
  );
});

export default MainBusiness;
